import useProductTour from '@hooks/useProductTour'
import { Button } from '@nextui-org/react'
import { PopoverContentProps } from '@reactour/tour'
import { useContext, useMemo } from 'react'
import { useIntl } from 'react-intl'
import { ProductTourContext } from './context/ProductTourContext'

export default function ContentComponent(props: PopoverContentProps) {
  const { currentStep, steps } = props
  const content = steps[currentStep].content

  const { formatMessage: f } = useIntl()
  const { next } = useProductTour()
  const ctx = useContext(ProductTourContext)

  const lastStep = useMemo(() => {
    return currentStep === steps.length - 1
  }, [currentStep, steps])

  const handleClick = () => {
    if (!ctx.isContinuous) return
    next()
  }

  const displayContent = (): string => {
    return (
      typeof content === 'function' ? content({ ...props }) : content
    ) as string
  }

  const badge = () => {
    return `${currentStep + 1} / ${steps.length}`
  }

  return (
    <section className="tour-content relative flex flex-col justify-between min-w-[280px] min-h-[140px]">
      <p className="pt-3 text-base">{f({ id: displayContent() })}</p>
      <div className="navigation flex justify-between items-end pt-8">
        <span className="font-bold">{badge()}</span>
        <Button
          disabled={!ctx.isContinuous}
          color="primary"
          auto
          onPress={handleClick}
        >
          {lastStep ? f({ id: 'tour.btn.close' }) : f({ id: 'tour.btn.next' })}
        </Button>
      </div>
    </section>
  )
}
