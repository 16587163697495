import PTourInsulatedCaptureControl from '@components/PTour/PTourInsulatedCaptureControl'
import PTourListOffers from '@components/PTour/PTourListOffers'
import { SubsectionWrapper } from '@components/SubsectionWrapper'
import useProductTour, {
  StepConditionCallbackType
} from '@hooks/useProductTour'
import FindLast from '@shared/FindLast'
import { useAppSelector } from '@store/hooks'
import { useCallback, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { StepConditionStrategy } from '../Shared/StepConditionStrategy'
import CaptureProductTour from './CaptureTourSteps'
import { StepFourCondition } from './StepConditions/StepFourCondition'
import { StepSevenCondition } from './StepConditions/StepSevenCondition'
import { StepSixCondition } from './StepConditions/StepSixCondition'
import WelcomeModal from './WelcomeModal'

export default function CaptureTour() {
  const { formatMessage: f } = useIntl()
  const { offers } = useAppSelector((state) => state.capture)

  const stepCondition = useCallback<StepConditionCallbackType>((step) => {
    const strategies: StepConditionStrategy[] = [
      new StepFourCondition(step, true),
      new StepSixCondition(step, true),
      new StepSevenCondition(step, true)
    ]
    const strategy = FindLast(strategies, (x) => x.IsActive())

    return strategy
  }, [])

  const { setIsOpen, resetTour } = useProductTour(
    CaptureProductTour,
    stepCondition
  )

  const [isOpenModal, setIsOpenModal] = useState(true)
  const handleContinue = () => {
    setIsOpenModal(false)
    setIsOpen(true)
  }

  useEffect(() => {
    resetTour()
  }, [])

  return (
    <section className="capture-tour flex flex-wrap gap-6 w-full">
      <WelcomeModal open={isOpenModal} handleContinue={handleContinue} />
      <PTourInsulatedCaptureControl />
      <SubsectionWrapper className="grow">
        <article className="mb-4 flex justify-between gap-6">
          <p className="font-bold">{f({ id: 'Offers captured' })}</p>
          <p className="text-sm text-neutral-400">
            <span className="pr-1">{offers?.length ?? 0}</span>
            {offers?.length > 1 ? f({ id: 'offers' }) : f({ id: 'offer' })}
          </p>
        </article>

        <PTourListOffers />
      </SubsectionWrapper>
    </section>
  )
}
