import useTourProgress from '@hooks/useTourProgress'
import { Dialog, Text } from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import { useIntl } from 'react-intl'
import TourProgressContent from './TourProgressContent'

export default function TourProgress() {
  const { formatMessage: f } = useIntl()
  const [opened, { toggle, close }] = useDisclosure(true)
  const { isOpen } = useTourProgress()

  return (
    <Dialog
      opened={isOpen}
      transition="slide-left"
      withCloseButton
      onClose={close}
      p={30}
      size="lg"
      radius="lg"
      shadow="xs"
      position={{ top: 80, right: 20 }}
    >
      <Text size="lg" mb="xs" weight={500}>
        {f({ id: 'Tour progression' })}
      </Text>
      <TourProgressContent showResetBtn={false} />
    </Dialog>
  )
}
