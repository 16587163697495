import { StepConditionStrategy } from '@components/ProductTour/Shared/StepConditionStrategy'

export class StepFourCondition extends StepConditionStrategy {
  constructor(step: number, condition: boolean) {
    super()
    const STEP_FOUR = 4

    this.id = STEP_FOUR
    this.step = step
    this.condition = condition
  }
}
