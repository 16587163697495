import Widget from '@components/widget'
import { Icons } from '@constants'
import { Earn } from '@models/Earn'
import { Text } from '@nextui-org/react'
import IsToday from '@shared/IsToday'
import { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'

export default function TodayEarn({ earns }: { earns: Earn[] }) {
  const { formatMessage: f } = useIntl()
  const [todayEarns, setTodayEarns] = useState(0)

  useEffect(() => {
    if (earns?.length > 0) {
      const _todayEarns = earns
        .filter((earn) => IsToday(earn.date))
        .reduce((acc, earn) => Number(acc) + Number(earn.value), 0)

      setTodayEarns(Number(_todayEarns.toFixed(2)))
    }
  }, [earns])

  return (
    <Widget
      title={f({ id: 'Today’s earns' })}
      icon={Icons.blocksIcons.wallet}
      gradient={{
        from: 'from-green-400',
        to: 'to-green-50'
      }}
      iconClass="rotate-[-29deg] font"
      className="min-w-min xs:col-span-2"
    >
      <Text className="text-4xl lg:text-5xl font-bold text-center md:text-left">
        ${todayEarns}
      </Text>
    </Widget>
  )
}
