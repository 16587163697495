import { IconButton } from '@components/IconButton'
import { defaultPicture } from '@constants/images'
import { TrashIcon } from '@heroicons/react/24/outline'
import { Offer } from '@models/Offer'
import { ServiceArea } from '@models/ServiceArea'
import moment from 'moment'
import Image from 'next/image'
import { Dispatch, SetStateAction } from 'react'
import { useIntl } from 'react-intl'
import LeftHours from './LeftHours'
import PriceAmount from './PriceAmount'
import { useTheme, Text, Tooltip } from '@nextui-org/react'

export default function ListItem({
  offer,
  selectedServiceArea,
  setCurrentOffer,
  actionHandler,
  moneySymbol
}: {
  offer: Offer
  selectedServiceArea: ServiceArea
  setCurrentOffer: Dispatch<SetStateAction<Offer>>
  actionHandler: (offer: Offer, action: string) => void
  moneySymbol: string
}) {
  const { formatMessage: f } = useIntl()
  const { theme } = useTheme()

  return (
    <li
      key={offer.offerId}
      className="nextui-table-row m-0 md:flex gap-4 items-center rounded-[20px] p-4 border-solid border-2"
      style={{
        borderColor: 'var(--nextui-colors-border)'
      }}
    >
      <div className="hidden xs:block xs:flex-none sm:w-auto mb-2">
        <Image src={defaultPicture} alt="station picture" quality={100} />
      </div>
      <div className="grow">
        <p className="text-blue-50 text-sm">
          {moment(offer.expirationDate).format('dddd, MM/DD')}
        </p>
        <p className="block-with-text text-sm xs:text-xs">
          <LeftHours
            endTime={offer.endTime}
            expirationDate={offer.expirationDate}
          />
        </p>
        <p className="text-base xs:text-sm">{selectedServiceArea?.name}</p>
      </div>
      <p></p>
      <p>
        <PriceAmount offer={offer} moneySymbol={moneySymbol} />
      </p>
      <div className="action-container">
        <Tooltip content={f({ id: 'Cancel block' })} color="error">
          <IconButton
            className={'action-icon cancel'}
            onClick={() => actionHandler(offer, 'cancel')}
          >
            <TrashIcon className="icon w-5 text-red-100" />
          </IconButton>
        </Tooltip>
      </div>
    </li>
  )
}
