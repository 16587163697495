import { Offer } from '@models/Offer'
import React from 'react'

export default function PriceAmount({
  offer,
  moneySymbol
}: {
  offer: Offer
  moneySymbol: string
}) {
  return (
    <>
      {offer.rateInfo.projectedTips !== undefined ? (
        <span className="font-bold">
          {moneySymbol}
          {offer.rateInfo.priceAmount} - {moneySymbol}
          {offer.rateInfo.projectedTips}
        </span>
      ) : (
        <span className="font-bold">
          {moneySymbol}
          {offer.rateInfo.priceAmount}
        </span>
      )}
    </>
  )
}
