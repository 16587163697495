import Widget from '@components/widget'
import { Icons } from '@constants'
import { ActivitiesStatistics } from '@models/ActivitiesStatistics'
import { Text } from '@nextui-org/react'
import { useIntl } from 'react-intl'

export default function TodayResult({
  statistics
}: {
  statistics: ActivitiesStatistics
}) {
  const { formatMessage: f } = useIntl()

  return (
    <Widget
      title={f({ id: 'Today’s results' })}
      icon={Icons.blocksIcons.statistics}
      iconClass="rotate-[29deg]"
      gradient={{
        from: 'from-blue-100',
        to: 'to-purple-50'
      }}
      className="w-auto col-span-2 sm:w-auto from-purple-50 to-purple-100"
    >
      <ul className="flex justify-center space-x-[30px] text-gray-100">
        <li className="">
          <Text className="text-sm md:text-xl">{f({ id: 'Captured' })}</Text>
          <Text className="text-2xl md:text-3xl font-bold text-center">
            {statistics?.todayStatistics?.captured}
          </Text>
        </li>
        <li className="">
          <Text className="text-sm md:text-xl">{f({ id: 'Skipped' })}</Text>
          <Text className="text-2xl md:text-3xl font-bold text-center">
            {statistics?.todayStatistics?.skipped}
          </Text>
        </li>
        <li className="">
          <Text className="text-sm md:text-xl">{f({ id: 'Listed' })}</Text>
          <Text className="text-2xl md:text-3xl font-bold text-center">
            {statistics?.todayStatistics?.listed}
          </Text>
        </li>
      </ul>
    </Widget>
  )
}
