import ListOffersContent from '@components/ListOffers/ListOffersContent'
import SelectMask from '@components/ProductTour/SelectMask'
import { useListOffers } from '@hooks/useListOffers'
import useProductTour from '@hooks/useProductTour'
import { useWindowSize } from '@hooks/useWindowSize'
import { Offer } from '@models/Offer'
import { useAppDispatch, useAppSelector } from '@store/hooks'
import { useEffect } from 'react'

export default function PTourListOffers() {
  const dispatch = useAppDispatch()
  const { offers } = useAppSelector((state) => state.productTour)
  const { next } = useProductTour()

  const { selectedServiceArea, setCurrentOffer, moneySymbol } = useListOffers()

  const actionTypes = {
    cancel: true
  }

  function actionHandler(offer: Offer, action: string) {
    if (actionTypes[action] !== undefined) {
      dispatch({ type: 'product_tour/cancelOffer', payload: offer })
      next(true)
    }
  }

  const { isOnResize } = useWindowSize()

  useEffect(() => {
    SelectMask('.list-blocks .list-blocks-content')
  }, [isOnResize, offers])

  return (
    <div className="product-tour-list-block">
      <ListOffersContent
        offers={offers}
        selectedServiceArea={selectedServiceArea}
        setCurrentOffer={setCurrentOffer}
        actionHandler={actionHandler}
        moneySymbol={moneySymbol}
      />
    </div>
  )
}
