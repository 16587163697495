import {
  TRIAL_PERIOD_CODE_REWARD,
  TRIAL_PERIOD_TIME_DAYS_MSG
} from '@constants/index'
import { Reward, RewardStatusType } from '@models/Reward'
import { useAppDispatch, useAppSelector } from '@store/hooks'
import { getRewards } from '@store/rewards/rewards.slice'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'

export interface UseRewardsType {
  rewards: Reward[]
  isDueTrialPeriod: boolean
}

export default function useRewards(): UseRewardsType {
  const { formatMessage: f } = useIntl()
  const dispatch = useAppDispatch()
  const { rewards } = useAppSelector((state) => state.rewards)

  const [isDueTrialPeriod, setIsDueTrialPeriod] = useState(false)

  const checkEndOfTrialPeriod = (reward: Reward): boolean => {
    const isValidTimeMsg =
      moment(reward.endDate).add(TRIAL_PERIOD_TIME_DAYS_MSG, 'day') > moment()

    if (
      reward.code === TRIAL_PERIOD_CODE_REWARD &&
      reward.status === RewardStatusType.Completed &&
      isValidTimeMsg
    ) {
      setIsDueTrialPeriod(true)
      return true
    }

    return false
  }

  useEffect(() => {
    if (rewards === undefined) {
      dispatch(getRewards())
      return
    }

    if (rewards === null) return

    for (const reward of rewards) {
      if (checkEndOfTrialPeriod(reward)) break
    }
  }, [rewards])

  return { rewards, isDueTrialPeriod }
}
