import { useListOffers } from '@hooks/useListOffers'
import ListOffersContent from './ListOffersContent'

export default function ListOffers() {
  const {
    offers,
    selectedServiceArea,
    setCurrentOffer,
    actionHandler,
    moneySymbol
  } = useListOffers()

  return (
    <ListOffersContent
      offers={offers}
      selectedServiceArea={selectedServiceArea}
      setCurrentOffer={setCurrentOffer}
      actionHandler={actionHandler}
      moneySymbol={moneySymbol}
    />
  )
}
