import moment from 'moment'

export default function LeftHours({
  endTime,
  expirationDate
}: {
  endTime: string
  expirationDate: string
}) {
  const exp = moment(expirationDate)
  const end = moment(endTime)

  // format (hh HR mm MN)
  const leftHoursHandler = () => {
    const diff = end.diff(exp, 'h', true)
    const hours = Math.trunc(diff)
    const minutes = Math.trunc((diff - hours) * 60)

    return {
      hours,
      minutes
    }
  }

  const leftHours = leftHoursHandler()
  const parenthesesOpening = '('
  const parenthesesClosing = ')'
  const separator = ' '

  return (
    <>
      <span className="pr-1">
        {exp.format('LT')} - {end.format('LT')}
      </span>
      <span>
        {parenthesesOpening}
        {leftHours.hours > 0 && (
          <span className="">
            <strong className="">{leftHours.hours}</strong> HR
          </span>
        )}
        {leftHours.hours > 0 && leftHours.minutes > 0 && separator}
        {leftHours.minutes > 0 && (
          <span className="">
            <strong className="">{leftHours.minutes}</strong> MN
          </span>
        )}
        {parenthesesClosing}
      </span>
    </>
  )
}
