import CaptureControlContainer from '@components/CaptureControl/CaptureControlContainer'
import useCaptureControl from '@hooks/useCaptureControl'
import useProductTour from '@hooks/useProductTour'
import { Capture } from '@models/Capture'
import Diff from '@shared/Diff'
import { useAppDispatch } from '@store/hooks'
import { captureRequest } from '@store/product_tour/product_tour.slice'
import { FormikHelpers } from 'formik'

export default function PTourInsulatedCaptureControl() {
  const {
    capture,
    toggleStartCapture,
    setToggleStartCapture
    // filtersFormValue
  } = useCaptureControl()

  const dispatch = useAppDispatch()
  const { next } = useProductTour()

  const handleSubmit = (
    values: Capture,
    formikHelpers: FormikHelpers<Capture>
  ) => {
    next(true)
    const diffValues = Diff<Capture>(capture, values)

    dispatch(captureRequest(diffValues))
  }

  return (
    <CaptureControlContainer
      className="product-tour-capture-control"
      // filtersFormValue={filtersFormValue}
      toggleStartCapture={toggleStartCapture}
      capture={capture}
      setToggleStartCapture={setToggleStartCapture}
      handleSubmit={handleSubmit}
    />
  )
}
