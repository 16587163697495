import BIcon from '@components/bIcon'
import { Icons } from '@constants/index'
import { Offer } from '@models/Offer'
import { ServiceArea } from '@models/ServiceArea'
import { Table } from '@nextui-org/react'
import React, { Dispatch, SetStateAction } from 'react'
import { useIntl } from 'react-intl'
import BodyCell from './BodyCell'
import CompactList from './CompactList'

export default function ListOffersContent({
  offers,
  selectedServiceArea,
  setCurrentOffer,
  actionHandler,
  moneySymbol
}: {
  offers: Offer[]
  selectedServiceArea: ServiceArea
  setCurrentOffer: Dispatch<SetStateAction<Offer>>
  actionHandler: (offer: Offer, action: string) => void
  moneySymbol: string
}) {
  const { formatMessage: f } = useIntl()
  const columns = [
    {
      key: 'station',
      label: f({ id: 'STATION' })
    },
    {
      key: 'expirationDate',
      label: f({ id: 'START DATE' })
    },
    {
      key: 'startEnd',
      label: f({ id: 'START - END TIME' })
    },
    {
      key: 'priceAmount',
      label: f({ id: 'AMOUNT + TIPS' })
    },
    {
      key: 'actions',
      label: 'ACTIONS'
    }
  ]

  return (
    <div className="list-blocks h-full">
      {offers?.length > 0 ? (
        <>
          <div className="list-blocks-content ss:hidden xs:hidden sm:hidden md:hidden lg:hidden xl:block">
            <Table
              shadow={false}
              aria-label={f({ id: 'table with a list of captured blocks' })}
              css={{
                height: 'auto',
                minWidth: '100%'
              }}
            >
              <Table.Header columns={columns}>
                {(column) => (
                  <Table.Column
                    key={column.key}
                    hideHeader={column.key === 'actions'}
                    align={column.key === 'actions' ? 'center' : 'start'}
                  >
                    {column.label}
                  </Table.Column>
                )}
              </Table.Header>
              <Table.Body>
                {offers.map((offer) => (
                  <Table.Row key={offer.offerId}>
                    {(columnKey) => (
                      <Table.Cell>
                        {BodyCell(
                          offer,
                          columnKey,
                          selectedServiceArea,
                          setCurrentOffer,
                          actionHandler,
                          moneySymbol
                        )}
                      </Table.Cell>
                    )}
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
          </div>

          {/* Mobile implementation */}
          <CompactList
            offers={offers}
            selectedServiceArea={selectedServiceArea}
            setCurrentOffer={setCurrentOffer}
            actionHandler={actionHandler}
            moneySymbol={moneySymbol}
          />
        </>
      ) : (
        <div className="flex justify-center items-center h-full py-2 md:py-4 text-center opacity-20">
          <div className="pb-10">
            <BIcon
              className="text-5xl md:text-6xl"
              icon={Icons.blocksIcons.factureMoney}
            />
            <p className="mt-2 lg:mt-4 text-sm md:text-base">
              {f({ id: 'No blocks captured yet' })}
            </p>
          </div>
        </div>
      )}
    </div>
  )
}
