import { StepConditionStrategy } from '@components/ProductTour/Shared/StepConditionStrategy'
import { ProductTourContext } from '@components/ProductTour/context/ProductTourContext'
import { ProductTour } from '@models/ProductTour'
import { useTour } from '@reactour/tour'
import { useAppDispatch } from '@store/hooks'
import { finishTourAndUpdate } from '@store/product_tour/product_tour.slice'
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useEffect
} from 'react'

export interface UseProductTourType {
  previous: () => void
  next: (bypass?: boolean) => void
  close: () => void
  resetTour: () => void
  setIsOpen: Dispatch<SetStateAction<boolean>>
}

export type StepConditionCallbackType = (step: number) => StepConditionStrategy

export default function useProductTour(
  productTour?: ProductTour,
  conditions?: StepConditionCallbackType
): UseProductTourType {
  const { tour, currentStep, steps, isContinuous, setCtxStates } =
    useContext(ProductTourContext)
  const dispatch = useAppDispatch()
  const { setIsOpen, setSteps } = useTour()

  const handleCallback = useCallback(
    (bypass: boolean): void => {
      // If haven't conditions continue to next step
      if (conditions?.(currentStep) === undefined) {
        nextStep(steps.length)
        return
      }

      const isActive: boolean = conditions(currentStep)?.IsActive() ?? false
      const evaluate: boolean = conditions(currentStep)?.Evaluate() ?? false

      if (isActive) {
        setCtxStates((prev) => ({ ...prev, isContinuous: false }))
      }

      if (bypass || evaluate) {
        setCtxStates((prev) => ({ ...prev, isContinuous: true }))
        nextStep(steps.length)
      }
    },
    [currentStep, conditions, conditions]
  )

  const previous = () => {
    const prevStep = Number(currentStep) - Number(1)
    setCtxStates((prev) => ({
      ...prev,
      currentStep: prevStep,
      isContinuous: true
    }))
  }

  const next = (bypass?: boolean) => {
    handleCallback(bypass)
  }

  const nextStep = (stepsLen: number) => {
    if (currentStep === stepsLen - 1) {
      close()
      return
    }
    const nextStep = Number(currentStep) + Number(1)

    setCtxStates((prev) => ({
      ...prev,
      currentStep: nextStep
    }))
  }

  const close = () => {
    setIsOpen(false)
    resetTour()

    // TODO: save current step status in database
    dispatch(finishTourAndUpdate(tour.tourId))
  }

  const resetTour = () => {
    setCtxStates((prev) => ({
      ...prev,
      currentStep: 0
    }))
  }

  useEffect(() => {
    if (productTour !== undefined) {
      setCtxStates((prevState) => ({
        ...prevState,
        tour: productTour,
        isContinuous: true
      }))
    }

    if (productTour === undefined) return

    setCtxStates((prev) => ({
      ...prev,
      steps: productTour.steps
    }))

    setSteps(productTour.steps)
  }, [productTour])

  useEffect(() => {
    if (conditions === undefined) return
    if (conditions(currentStep) === undefined) {
      setCtxStates((prev) => ({ ...prev, isContinuous: true }))
      return
    }

    if (conditions(currentStep)?.IsActive() === true) {
      setCtxStates((prev) => ({ ...prev, isContinuous: false }))
    }
  }, [currentStep])

  // Update isContinuous
  const DEFAULT_IS_CONTINUOUS = true
  useEffect(() => {
    if (conditions === undefined) return

    const evaluate: boolean =
      conditions(currentStep)?.Evaluate() ?? DEFAULT_IS_CONTINUOUS
    if (isContinuous !== evaluate)
      setCtxStates((prev) => ({ ...prev, isContinuous: evaluate }))
  }, [conditions])

  return {
    previous,
    next,
    close,
    resetTour,
    setIsOpen
  }
}
