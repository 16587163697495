import { UPDATE_STATISTICS_INTERVAL_MS } from '@constants/index'
import { ActivitiesStatistics } from '@models/ActivitiesStatistics'
import { DateRange } from '@models/dtos/DateRange'
import { PeriodType } from '@models/dtos/PeriodType'
import { Earn } from '@models/Earn'
import { activitiesStatisticsRequest } from '@store/dashboard/activities_statistics.slice'
import { earnsRequest } from '@store/dashboard/earns.slice'
import { useAppDispatch, useAppSelector } from '@store/hooks'
import moment from 'moment'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'

export interface UseActivitiesStatisticsReturnType {
  statistics: ActivitiesStatistics
  periodEarn: PeriodType
  setPeriodEarn: Dispatch<SetStateAction<PeriodType>>
  earns: Earn[]
  totalEarn: number
  convertEarnsToChartData: () => void
}

export function useActivitiesStatistics(): UseActivitiesStatisticsReturnType {
  const dispatch = useAppDispatch()
  const { statistics } = useAppSelector((state) => state.activitiesStatistics)
  const { earns } = useAppSelector((state) => state.earns)
  const { offers } = useAppSelector((state) => state.capture)

  const { isShowTour: showTour } = useAppSelector((state) => state.productTour)

  const [periodEarn, setPeriodEarn] = useState<PeriodType>('month')
  // const [earns, setEarns] = useState<Array<{ name: string; earn: number }>>([])
  const [totalEarn, setTotalEarn] = useState(0)

  const convertEarnsToChartData = () => {
    if (earns === undefined) return

    earns?.filter((earn) => earn.value > 50)
    setTotalEarn(earns?.length)
  }

  useEffect(() => {
    if (offers === undefined) return

    // Default range is weekly
    let dateRange: DateRange = {
      start: moment().startOf('week').utc().format(),
      end: moment().endOf('week').utc().format()
    }

    if (periodEarn === 'month') {
      dateRange = {
        start: moment().startOf('month').utc().format(),
        end: moment().endOf('month').utc().format()
      }
    }
    dispatch(earnsRequest(dateRange))
  }, [dispatch, offers, periodEarn])

  useEffect(() => {
    if (showTour === true) return

    if (earns === undefined) {
      // Default range is weekly
      let dateRange: DateRange = {
        start: moment().startOf('week').utc().format(),
        end: moment().endOf('week').utc().format()
      }

      if (periodEarn === 'month') {
        dateRange = {
          start: moment().startOf('month').utc().format(),
          end: moment().endOf('month').utc().format()
        }
      }
      dispatch(earnsRequest(dateRange))
    }

    dispatch(activitiesStatisticsRequest(undefined))
    const intervalCall = setInterval(() => {
      dispatch(activitiesStatisticsRequest(undefined))
    }, UPDATE_STATISTICS_INTERVAL_MS)

    return () => {
      clearInterval(intervalCall)
    }
  }, [dispatch, showTour, earns, periodEarn])

  return {
    statistics,
    periodEarn,
    setPeriodEarn,
    earns,
    totalEarn,
    convertEarnsToChartData
  }
}
