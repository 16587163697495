import { Button, Modal, Text } from '@nextui-org/react'
import { useIntl } from 'react-intl'

export default function WelcomeModal({
  open,
  handleContinue
}: {
  open: boolean
  handleContinue: () => void
}) {
  const { formatMessage: f } = useIntl()

  return (
    <Modal open={open}>
      <Modal.Header>
        <Text>{f({ id: 'welcome.modal.tour.title' })}</Text>
      </Modal.Header>
      <Modal.Body>
        <Text>{f({ id: 'welcome.modal.tour.text1' })}</Text>
        <Text>{f({ id: 'welcome.modal.tour.text2' })}</Text>
        <Text>{f({ id: 'welcome.modal.tour.footer' })}</Text>
      </Modal.Body>
      <Modal.Footer>
        <Button onPress={handleContinue}>{f({ id: 'Continue' })} </Button>
      </Modal.Footer>
    </Modal>
  )
}
