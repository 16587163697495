import { ClassNames } from 'shared'
import { Text, useTheme } from '@nextui-org/react'
import BChart from './BChart'
import BIcon from './bIcon'
import { Gradient } from './widget'

export default function WidgetChart({
  className,
  children,
  title,
  icon,
  iconClass,
  gradient,
  value,
  totalEarn
}: {
  className?: string
  children?: React.ReactNode
  title?: string
  icon?: string
  iconClass?: string
  gradient?: Gradient
  value: Array<{ name: string; value: number }>
  totalEarn: number
}) {
  const { theme } = useTheme()

  const separation = children !== undefined ? 'mb-2' : 'mb-6'

  return (
    <div
      className={ClassNames(
        className,
        'widget flex min-h-[140px] p-4 lg:py-5 lg:px-6 rounded-[30px] lg:rounded-[40px] shadow-xl'
      )}
      style={{
        background: theme?.colors?.accents3?.value
      }}
    >
      {icon != null && (
        <>
          <div className="mr-2">
            <div className={ClassNames('flex items-center', separation)}>
              <div
                className={ClassNames(
                  'icon-wrap flex flex-none justify-center items-center w-10 h-10 lg:w-[57px] lg:h-[57px] rounded-2xl lg:rounded-3xl bg-gradient bg-gradient-to-tl',
                  gradient?.from,
                  gradient?.to
                )}
              >
                <BIcon
                  className={ClassNames(iconClass, 'text-xl lg:text-3xl')}
                  icon={icon}
                  color="white"
                />
              </div>
              <Text className="ml-4 text-sm md:text-base lg:text-lg font-bold md:font-normal">
                {title}
              </Text>
            </div>

            {children}

            <Text className="text-4xl lg:text-5xl font-bold text-center md:text-left">
              ${totalEarn}
            </Text>
          </div>
          <div className="w-full min-w-[160px]  max-w-[160px] sm:max-w-[210px] md:max-w-[210px]">
            {/* <div className="w-full"> */}
            {value.length > 0 && <BChart data={value} />}
          </div>
        </>
      )}
    </div>
  )
}
