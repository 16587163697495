export enum StatusType {
  Accepted,
  Rejected,
  Skipped,
  Missing
}

export enum ReasonType {
  Row,
  Gone,
  Expired,
  Ongoing,
  Completed
}

export interface StatusReason {
  status: StatusType
  reason: ReasonType
}
