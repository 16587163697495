import useProductTour from '@hooks/useProductTour'
import { StepType, TourProvider } from '@reactour/tour'
import React, { Dispatch, useContext } from 'react'
import ContentComponent from './ContentComponent'
import NextButton from './NextButton'
import PrevButton from './PrevButton'
import TourProgress from './TourProgress/TourProgress'
import { ProductTourContext } from './context/ProductTourContext'

interface NavButtonProps {
  onClick?: () => void
  kind?: 'next' | 'prev'
  hideArrow?: boolean
}

export interface BtnFnProps {
  Button: React.FC<React.PropsWithChildren<NavButtonProps>>
  setCurrentStep: Dispatch<React.SetStateAction<number>>
  stepsLength: number
  currentStep: number
  setIsOpen: Dispatch<React.SetStateAction<boolean>>
  steps?: StepType[]
}

export default function ProductTourComponent({
  children
}: {
  children: React.ReactNode
}) {
  const ctx = useContext(ProductTourContext)
  const { close } = useProductTour()

  const DISABLE_ESC_KEY = 'esc'
  const BORDER_RADIUS = 16

  return (
    <TourProvider
      ContentComponent={ContentComponent}
      steps={ctx.steps}
      currentStep={ctx.currentStep}
      prevButton={PrevButton}
      nextButton={NextButton}
      onClickClose={close}
      onClickMask={() => ({})}
      showCloseButton={false}
      padding={10}
      disableKeyboardNavigation={[DISABLE_ESC_KEY]}
      disableDotsNavigation
      showDots={false}
      styles={{
        popover: (base) => ({
          ...base,
          padding: 20,
          borderRadius: BORDER_RADIUS
        }),
        maskArea: (base) => ({ ...base, rx: 16 }),
        maskWrapper: (base) => ({ ...base, color: '#47474e' }),
        controls: (base) => ({
          ...base,
          marginTop: 100
        })
      }}
    >
      <TourProgress />
      {children}
    </TourProvider>
  )
}
