export default function SelectMask(selector) {
  const masks = document.querySelectorAll<HTMLElement>(selector)
  const ACTIVE_MASK_CLS = 'active-mask'

  for (let i = 0; i < masks.length; i++) {
    const style = getComputedStyle(masks[i])

    if (!masks[i].classList.contains(ACTIVE_MASK_CLS)) {
      masks[i].classList.add(ACTIVE_MASK_CLS)
    }

    if (style.display !== 'none') break
    masks[i].classList.remove(ACTIVE_MASK_CLS)
  }
}
