import { IconButton } from '@components/IconButton'
import { TrashIcon } from '@heroicons/react/24/outline'
import { Offer } from '@models/Offer'
import { Col, Row, Tooltip } from '@nextui-org/react'
import { useIntl } from 'react-intl'

export default function ActionsCell({
  offer,
  actionHandler
}: {
  offer: Offer
  actionHandler: (offer: Offer, action: string) => void
}) {
  const { formatMessage: f } = useIntl()

  return (
    <Row justify="center" align="center">
      <Col css={{ d: 'flex' }}>
        <Tooltip content={f({ id: 'Cancel block' })} color="error">
          <IconButton
            className={'action-icon cancel'}
            onClick={() => actionHandler(offer, 'cancel')}
          >
            <TrashIcon className="icon w-5 text-red-100" />
          </IconButton>
        </Tooltip>
      </Col>
    </Row>
  )
}
