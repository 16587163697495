import useCaptureControl from '@hooks/useCaptureControl'
import { Capture } from '@models/Capture'
import { captureRequest } from '@store/dashboard/capture.slice'
import { useAppDispatch } from '@store/hooks'
import { FormikHelpers } from 'formik'
import CaptureControlContainer from './CaptureControlContainer'

export default function InsulatedCaptureControl() {
  const { capture, toggleStartCapture, setToggleStartCapture } =
    useCaptureControl()

  const dispatch = useAppDispatch()

  const handleSubmit = (
    values: Capture,
    formikHelpers: FormikHelpers<Capture>
  ) => {
    // TODO: convert dto to entity

    // TODO: extract differences
    // const diffValues = Diff<Capture>(capture, values)
    dispatch(captureRequest(values))
    console.log('submit capture', values)
  }

  // NOTE: if needed for ServiceArea selector
  // const handlePickServiceAreas = () => {
  //   setShowPickSlide(true)
  // }
  // const handleGoBack = () => {
  //   setShowPickSlide(false)
  // }

  return (
    <CaptureControlContainer
      isInsulated
      toggleStartCapture={toggleStartCapture}
      capture={capture}
      setToggleStartCapture={setToggleStartCapture}
      handleSubmit={handleSubmit}
    />
  )
}
