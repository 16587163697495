import { useActivitiesStatistics } from '@hooks/useActivitiesStatistics'
import { SwitchEvent } from '@nextui-org/react'
import { useEffect } from 'react'
import MonthWeekEarns from './MonthWeekEarns'
import TodayCaptured from './TodayCaptured'
import TodayEarn from './TodayEarn'
import TodayResult from './TodayResult'

export default function ActivitiesStatistics() {
  const {
    statistics,
    earns,
    totalEarn,
    convertEarnsToChartData,
    periodEarn,
    setPeriodEarn
  } = useActivitiesStatistics()

  useEffect(() => {
    convertEarnsToChartData()
  }, [statistics, periodEarn, convertEarnsToChartData])

  const onChangePeriod = (e: SwitchEvent) => {
    /* eslint-disable @typescript-eslint/no-unnecessary-type-assertion */
    const period = (e.target.checked as boolean) ? 'month' : 'week'
    setPeriodEarn(period)
  }

  return (
    <div className="section-container lg:grid-cols-none lg:flex lg:flex-wrap md:grid-cols-3 xs:grid xs:grid-cols-2 space-y-4 xs:space-y-0 gap-4 sm:gap-[30px]">
      <TodayEarn earns={earns} />
      <MonthWeekEarns
        earns={earns}
        periodEarn={periodEarn}
        onChangePeriod={onChangePeriod}
      />
      <TodayCaptured statistics={statistics} />
      <TodayResult statistics={statistics} />
    </div>
  )
}
