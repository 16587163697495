import { ScrollArea } from '@mantine/core'
import { Offer } from '@models/Offer'
import { ServiceArea } from '@models/ServiceArea'
import { Dispatch, SetStateAction } from 'react'
import ListItem from './ListItem'

export default function CompactList({
  offers,
  selectedServiceArea,
  setCurrentOffer,
  actionHandler,
  moneySymbol
}: {
  offers: Offer[]
  selectedServiceArea: ServiceArea
  setCurrentOffer: Dispatch<SetStateAction<Offer>>
  actionHandler: (offer: Offer, action: string) => void
  moneySymbol: string
}) {
  return (
    <ScrollArea type="auto">
      <ul className="list-blocks-content compact-list space-y-4 xl:hidden max-h-[420px] overflow-y-auto">
        {offers.map((offer) => (
          <ListItem
            key={offer.offerId}
            offer={offer}
            selectedServiceArea={selectedServiceArea}
            setCurrentOffer={setCurrentOffer}
            actionHandler={actionHandler}
            moneySymbol={moneySymbol}
          />
        ))}
      </ul>
    </ScrollArea>
  )
}
