import { StepConditionStrategy } from '@components/ProductTour/Shared/StepConditionStrategy'

export class StepSixCondition extends StepConditionStrategy {
  constructor(step: number, condition: boolean) {
    super()
    const STEP_SIX = 6

    this.id = STEP_SIX
    this.step = step
    this.condition = condition
  }
}
