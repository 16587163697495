import { StepConditionStrategy } from '@components/ProductTour/Shared/StepConditionStrategy'

export class StepSevenCondition extends StepConditionStrategy {
  constructor(step: number, condition: boolean) {
    super()
    const STEP_SEVEN = 7

    this.id = STEP_SEVEN
    this.step = step
    this.condition = condition
  }
}
