import { colors } from '@constants/colors'
import { PureComponent } from 'react'
import { Area, AreaChart, ResponsiveContainer, Tooltip } from 'recharts'

export interface ChartData {
  name: string
  value: number
}
interface Props {
  data: ChartData[]
}

/* interface State {} */

export default class BChart extends PureComponent<Props> {
  render() {
    const values = [...this.props.data]
    const lastValue = this.props.data[this.props.data.length - 1]

    const sortArray = values.sort((a, b) => a.value - b.value)
    const higherValue = sortArray[sortArray.length - 1]

    return (
      <ResponsiveContainer>
        <AreaChart
          data={this.props.data}
          margin={{
            top: 24,
            right: 20,
            left: 24,
            bottom: 5
          }}
        >
          <defs>
            <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
              <stop
                offset="1%"
                stopColor={colors.green[250]}
                stopOpacity={0.8}
              />
              <stop
                offset="99%"
                stopColor={colors.green[250]}
                stopOpacity={0}
              />
            </linearGradient>
          </defs>
          <Tooltip
            content={<CustomTooltip />}
            cursor={{
              stroke: colors.green[250],
              strokeWidth: 2,
              strokeDasharray: '5 5'
            }}
          />
          <Area
            type="monotone"
            dataKey="value"
            stroke={colors.green[250]}
            strokeWidth={2}
            fill="url(#colorPv)"
            label={
              <CustomizedLabel
                value={lastValue.value}
                stroke={colors.green[200]}
                higher={higherValue.value}
              />
            }
            dot={
              <CustomDots
                color={colors.green[250]}
                lastValue={lastValue.value}
                higher={higherValue.value}
              />
            }
          />
        </AreaChart>
      </ResponsiveContainer>
    )
  }
}

function CustomTooltip({
  payload,
  label,
  active
}: {
  payload?: any
  label?: string
  active?: any
}) {
  if (active === undefined) return

  return (
    <div className="custom-tooltip">
      <p className="label">{`$${String(payload[0]?.value)}`}</p>
    </div>
  )
}

function CustomDots({
  cx,
  cy,
  stroke,
  payload,
  value,
  lastValue,
  higher,
  color
}: {
  cx?: number
  cy?: number
  stroke?: any
  payload?: number
  value?: any
  lastValue?: any
  higher?: number
  color: string
}) {
  if (higher !== value[1]) return

  return (
    <svg
      x={cx - 10}
      y={cy - 10}
      width={20}
      height={20}
      fill="red"
      viewBox="0 0 64 64"
    >
      <circle
        cx={32}
        cy={32}
        r={13}
        stroke={color}
        strokeWidth={5}
        fill={'white'}
      />
    </svg>
  )
}

function CustomizedLabel({
  x,
  y,
  stroke,
  value,
  higher
}: {
  x?: number
  y?: number
  stroke?: string
  value: number
  higher: number
}) {
  if (higher !== value) return

  return (
    <text
      x={x}
      y={y}
      dy={-8}
      fill={stroke}
      fontSize={10}
      fontWeight="bold"
      textAnchor={'middle'}
    >
      {value}
    </text>
  )
}
