export default function FindLast<T>(
  arr: T[],
  predicate: (value: T, index: number, obj: T[]) => boolean
): T | undefined {
  for (let i = arr.length - 1; i >= 0; i--) {
    const value = arr[i]
    if (predicate(value, i, arr)) {
      return value
    }
  }
  return undefined
}
