import useProductTour from '@hooks/useProductTour'
import React from 'react'
import { BtnFnProps } from './ProductTour'

export default function PrevButton({
  Button,
  currentStep,
  stepsLength,
  setIsOpen,
  setCurrentStep,
  steps
}: BtnFnProps) {
  const { previous } = useProductTour()
  return (
    <Button
      kind="prev"
      onClick={() => {
        previous()
      }}
    ></Button>
  )
}
