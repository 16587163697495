import ProductTourComponent from '@components/ProductTour/ProductTour'
import { StepType } from '@reactour/tour'
import {
  Dispatch,
  SetStateAction,
  createContext,
  useEffect,
  useState
} from 'react'
import { StepConditionStrategy } from '../Shared/StepConditionStrategy'
import { useAppDispatch } from '@store/hooks'
import { tourRequest } from '@store/product_tour/product_tour.slice'
import { ProductTour } from '@models/ProductTour'

export interface ProductTourState {
  tour: ProductTour | undefined
  steps: StepType[]
  currentStep: number
  isContinuous: boolean
  isOpen: boolean
  stepCondition: StepConditionCallbackType | undefined
}

export type StepConditionCallbackType = (step: number) => StepConditionStrategy

export type ProductTourValue = ProductTourState & {
  setCtxStates: Dispatch<SetStateAction<ProductTourState>>
}

export const ProductTourContext = createContext<ProductTourValue>(null)

export function ProductTourProvider({ children }: { children: JSX.Element }) {
  const dispatch = useAppDispatch()
  const [ctxStates, setCtxStates] = useState<ProductTourState>({
    tour: undefined,
    steps: [],
    currentStep: 0,
    isContinuous: true,
    isOpen: true,
    stepCondition: undefined
  })

  useEffect(() => {
    dispatch(tourRequest(undefined))
  }, [])

  return (
    <ProductTourContext.Provider value={{ ...ctxStates, setCtxStates }}>
      <ProductTourComponent>{children}</ProductTourComponent>
    </ProductTourContext.Provider>
  )
}
