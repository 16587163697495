import { IconButton } from '@components/IconButton'
import { ArrowPathIcon } from '@heroicons/react/24/outline'
import { TourStatus } from '@models/TourStatus'
import { Tooltip } from '@nextui-org/react'
import { useAppDispatch } from '@store/hooks'
import {
  RouteIds,
  retakeTourRequest
} from '@store/product_tour/product_tour.slice'
import { useIntl } from 'react-intl'

export default function ResetBtn({ tourStatus }: { tourStatus: TourStatus }) {
  const { formatMessage: f } = useIntl()
  const dispatch = useAppDispatch()

  const handleClick = () => {
    dispatch(retakeTourRequest(tourStatus.tour.uuid))
    dispatch({
      type: 'navigation/changeActiveRoute',
      payload: RouteIds.get(tourStatus.tour.uuid)
    })
  }

  return (
    <Tooltip content={f({ id: 'Retake tour' })} color="primary">
      <IconButton className={'action-icon reset'} onClick={handleClick}>
        <ArrowPathIcon className="icon w-5" />
      </IconButton>
    </Tooltip>
  )
}
