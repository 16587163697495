import Delay from '@shared/Delay'
import { useEffect, useState } from 'react'

export interface WindowSizeType {
  width: number | undefined
  height: number | undefined
}

interface UseOnResizeReturnType {
  isOnResize: boolean
  windowSize: WindowSizeType
}

export function useWindowSize(): UseOnResizeReturnType {
  const [isOnResize, setIsOnResize] = useState(false)
  const [windowSize, setWindowSize] = useState<WindowSizeType>({
    width: undefined,
    height: undefined
  })

  useEffect(() => {
    function handleResize() {
      setIsOnResize(true)
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight
      })

      Delay(300).then(() => {
        setIsOnResize(false)
      })
    }
    window.addEventListener('resize', handleResize)

    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return {
    isOnResize,
    windowSize
  }
}
