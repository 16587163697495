export abstract class StepConditionStrategy {
  protected id: number
  protected step: number
  protected condition: boolean

  IsActive(): boolean {
    return this.id === this.step
  }

  Evaluate(): boolean {
    return this.condition
  }
}
