import ActivitiesStatistics from '@components/ActivitiesStatistics'
import InsulatedCaptureControl from '@components/CaptureControl/InsulatedCaptureControl'
import ListOffers from '@components/ListOffers'
import CaptureTour from '@components/ProductTour/Capture/CaptureTour'
import CaptureProductTour from '@components/ProductTour/Capture/CaptureTourSteps'
import { SubsectionWrapper } from '@components/SubsectionWrapper'
import SectionWidget from '@components/sectionWidget'
import useRewards from '@hooks/useRewards'
import { Alert } from '@models/dtos/Alert'
import { useAppDispatch, useAppSelector } from '@store/hooks'
import { settingsRequest } from '@store/settings/settings.slice'
import { useEffect } from 'react'
import { useIntl } from 'react-intl'

export default function Dashboard({
  handleActiveTour
}: {
  handleActiveTour: (tourId: string) => boolean
}) {
  const { formatMessage: f } = useIntl()
  const dispatch = useAppDispatch()
  const { offers } = useAppSelector((state) => state.capture)
  const { isLoading, settings } = useAppSelector((state) => state.settings)
  const { rewards } = useAppSelector((state) => state.rewards)
  const { isDueTrialPeriod } = useRewards()

  useEffect(() => {
    if (settings === undefined) dispatch(settingsRequest(undefined))
  }, [dispatch, settings])

  useEffect(() => {
    if (isDueTrialPeriod === false) return

    dispatch({
      type: 'notifications/add',
      payload: new Alert(
        f({ id: 'Trial period has ended' }),
        f({ id: 'Our payment starting to procced today' })
      )
    })
  }, [dispatch, f, isDueTrialPeriod])

  return (
    <main id="dashboard" className="">
      <header className="mb-[60px]">
        <h1 className="text-3xl md:text-5xl font-bold ">
          <span className="block text-xl md:text-3xl font-normal">
            {f({ id: 'today’s' })}
          </span>
          {f({ id: 'Activities' })}
        </h1>
      </header>
      <section className="container space-y-[30px]">
        <ActivitiesStatistics />

        <div className="flex flex-wrap gap-[30px]">
          <SectionWidget
            title={f({ id: 'Filter and Capture' })}
            className="mb-0 shadow-xl w-full"
          >
            {handleActiveTour(CaptureProductTour.tourId) ? (
              <CaptureTour />
            ) : (
              <section className="flex flex-wrap gap-6 w-full">
                <InsulatedCaptureControl />

                <SubsectionWrapper className="grow">
                  <article className="mb-4 flex justify-between gap-6">
                    <p className="font-bold">{f({ id: 'Offers captured' })}</p>
                    <p className="text-sm text-neutral-400">
                      <span className="pr-1">{offers?.length ?? 0}</span>
                      {offers?.length > 1
                        ? f({ id: 'offers' })
                        : f({ id: 'offer' })}
                    </p>
                  </article>

                  <ListOffers />
                </SubsectionWrapper>
              </section>
            )}
          </SectionWidget>
        </div>
      </section>
    </main>
  )
}
