import { colors } from '@constants/colors'
import { SHOW_TOUR_PROGRESSION_DELAY } from '@constants/index'
import { MantineColor } from '@mantine/core'
import { TourStatus, TourStatusType } from '@models/TourStatus'
import Delay from '@shared/Delay'
import { useAppDispatch, useAppSelector } from '@store/hooks'
import { useCallback, useEffect, useState } from 'react'

export interface UseTourProgressReturnType {
  isOpen: boolean
  tourStatuses: TourStatus[]
  progression: number
  sections: RingProgressSection[]
}

export interface TourType {
  name: string
  color: string
  value: number
}

interface RingProgressSection extends React.ComponentPropsWithRef<'circle'> {
  value: number
  color: MantineColor
  tooltip?: React.ReactNode
}
// const colors: string[] = ['cyan', 'orange', 'red', 'grape']

export default function useTourProgress(): UseTourProgressReturnType {
  const dispatch = useAppDispatch()
  const { tourStatuses, isShowTourProgress } = useAppSelector(
    (x) => x.productTour
  )
  const [progression, setProgression] = useState(0)
  const [sections, setSections] = useState<RingProgressSection[]>([])

  const genRingSection = useCallback(
    (tours: TourType[], progression: number): RingProgressSection[] => {
      const getTourValue = (
        percent: number,
        value: number,
        max: number
      ): number => {
        return percent >= value && 100 / max
      }

      return tours.map((tour, index) => ({
        value: getTourValue(progression, tour.value, tours.length),
        color: tour.color
      }))
    },
    []
  )

  useEffect(() => {
    if (isShowTourProgress === false) return
    Delay(SHOW_TOUR_PROGRESSION_DELAY).then(() => {
      dispatch({
        type: 'product_tour/updateShowTourProgression',
        payload: false
      })
    })
  }, [isShowTourProgress])

  useEffect(() => {
    if (tourStatuses === undefined) return

    let totalProgression = 0
    const sliceValue = 100 / tourStatuses.length

    tourStatuses.forEach((value) => {
      if (value.status === TourStatusType.Finished) {
        totalProgression += sliceValue
      }
    })

    const toursType: TourType[] = tourStatuses.map((current, index: number) => {
      return {
        name: current.tour.name,
        color: colors.blue[200],
        value: (100 / tourStatuses.length) * (index + 1)
      }
    })

    setProgression(totalProgression)
    setSections(genRingSection(toursType, progression))
  }, [genRingSection, progression, tourStatuses])

  return {
    isOpen: isShowTourProgress,
    tourStatuses,
    progression,
    sections
  }
}
