import ActionsCell from '@components/ListOffers/ActionsCell'
import { Offer } from '@models/Offer'
import { ServiceArea } from '@models/ServiceArea'
import moment from 'moment'
import { Dispatch, Key, SetStateAction } from 'react'
import LeftHours from './LeftHours'

export default function BodyCell(
  offer: Offer,
  columnKey: Key,
  selectedServiceArea: ServiceArea,
  setCurrentOffer: Dispatch<SetStateAction<Offer>>,
  actionHandler: (offer: Offer, action: string) => void,
  moneySymbol: string
) {
  setCurrentOffer(offer)

  const cell = {
    station: (
      // <PictureCell
      //   img={'https://i.pravatar.cc/150'}
      //   value={selectedServiceArea?.name}
      //   body={''}
      // />
      <span>{selectedServiceArea?.name}</span>
    ),
    expirationDate: (
      <span>{moment(offer.expirationDate).format('dddd, MM/DD')}</span>
    ),
    startEnd: (
      <LeftHours
        endTime={offer.endTime}
        expirationDate={offer.expirationDate}
      />
    ),
    priceAmount: (
      <>
        {offer.rateInfo.projectedTips !== undefined ? (
          <span className="font-bold">
            {moneySymbol}
            {offer.rateInfo.priceAmount} + {moneySymbol}
            {offer.rateInfo.projectedTips}
          </span>
        ) : (
          <span className="font-bold">
            {moneySymbol}
            {offer.rateInfo.priceAmount}
          </span>
        )}
      </>
    ),
    actions: <ActionsCell offer={offer} actionHandler={actionHandler} />
  }

  return cell[columnKey] ?? offer[columnKey]
}
