import { ClassNames } from 'shared'
import { useTheme, Text } from '@nextui-org/react'
import BIcon from './bIcon'

export interface Gradient {
  from: string
  to: string
}

export default function Widget({
  className,
  children,
  title,
  icon,
  iconClass,
  gradient
}: {
  className?: string
  children: React.ReactNode
  title?: string
  icon?: string
  iconClass?: string
  gradient?: Gradient
}) {
  const { theme } = useTheme()

  return (
    <div
      className={ClassNames(
        className,
        'widget w-auto min-h-[140px] p-4 lg:py-5 lg:px-6 rounded-[30px] lg:rounded-[40px] shadow-xl'
      )}
      style={{
        background: theme?.colors?.accents3?.value
      }}
    >
      {icon != null ? (
        <div className="flex items-center mb-6">
          <div
            className={ClassNames(
              'icon-wrap flex flex-none justify-center items-center w-10 h-10 lg:w-[57px] lg:h-[57px] rounded-2xl lg:rounded-3xl bg-gradient bg-gradient-to-tl',
              gradient?.from,
              gradient?.to
            )}
          >
            <BIcon
              className={ClassNames(iconClass, 'text-xl lg:text-3xl')}
              icon={icon}
              color="white"
            />
          </div>
          <Text className="ml-4 text-sm md:text-base lg:text-lg font-bold md:font-normal">
            {title}
          </Text>
        </div>
      ) : (
        ''
      )}
      {children}
      {/* <PresentationChartLineIcon className='w-6 text-white' /> */}
      {/* </span> */}
    </div>
  )
}
