import WidgetChart from '@components/WidgetChart'
import { Icons } from '@constants'
import { PeriodType } from '@models/dtos/PeriodType'
import { Earn } from '@models/Earn'
import { SwitchEvent } from '@nextui-org/react'
import { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'

export default function MonthWeekEarns({
  earns,
  onChangePeriod,
  periodEarn
}: {
  earns: Earn[]
  periodEarn: PeriodType
  onChangePeriod: (ev: SwitchEvent) => void
}) {
  const { formatMessage: f } = useIntl()
  const [chartValue, setChartValue] = useState([])
  const [totalEarn, setTotalEarn] = useState(0)

  const chartTitles = {
    week: f({ id: 'Week’s earns' }),
    month: f({ id: 'Month’s earns' })
  }

  useEffect(() => {
    const values = []
    let total = 0

    earns?.forEach((earn) => {
      values.push({
        name: earn.date,
        value: Number(earn.value.toFixed(2))
      })

      total = total + Number(earn.value)
    })

    setChartValue(values)
    setTotalEarn(Number(total.toFixed(2)))
  }, [earns])

  return (
    <WidgetChart
      value={chartValue}
      totalEarn={totalEarn}
      title={chartTitles[periodEarn]}
      icon={Icons.blocksIcons.wallet}
      gradient={{
        from: 'from-blue-100',
        to: 'to-green-300'
      }}
      iconClass="rotate-[-29deg]"
      className="week-earns min-w-min md:col-span-2 lg:w-[415px] xs:col-span-2"
    >
      {/* <Switch onChange={onChangePeriod} /> */}
    </WidgetChart>
  )
}
