import Widget from '@components/widget'
import { Icons } from '@constants'
import { ActivitiesStatistics } from '@models/ActivitiesStatistics'
import { Text } from '@nextui-org/react'
import { useIntl } from 'react-intl'

export default function TodayCaptured({
  statistics
}: {
  statistics: ActivitiesStatistics
}) {
  const { formatMessage: f } = useIntl()

  return (
    <Widget
      title={f({ id: 'Today’s captured' })}
      icon={Icons.blocksIcons.fishing}
      gradient={{
        from: 'from-blue-100',
        to: 'to-blue-200'
      }}
      iconClass=""
      className="today-captured min-w-min hidden md:block "
    >
      <Text className="text-4xl lg:text-5xl font-bold text-center md:text-left">
        {statistics?.todayStatistics?.captured}
      </Text>
    </Widget>
  )
}
