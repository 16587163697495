import { UPDATE_OFFERS_CAPTURED_INTERVAL_MS } from '@constants/index'
import { RejectOffersRequest } from '@models/dtos/RejectOffersRequest'
import { ReasonType, StatusReason, StatusType } from '@models/dtos/StatusReason'
import { Offer } from '@models/Offer'
import { ServiceArea } from '@models/ServiceArea'
import { offersRequest, rejectOffers } from '@store/dashboard/capture.slice'
import { useAppDispatch, useAppSelector } from '@store/hooks'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'

export interface UseListOffersReturnType {
  offers: Offer[]
  selectedServiceArea: ServiceArea
  setSelectedServiceArea: Dispatch<SetStateAction<ServiceArea>>
  setCurrentOffer: Dispatch<SetStateAction<Offer>>
  actionHandler: (offer: Offer, action: string) => void
  moneySymbol: string
}

export function useListOffers(): UseListOffersReturnType {
  const dispatch = useAppDispatch()
  const { offers } = useAppSelector((state) => state.capture)
  const { serviceAreas } = useAppSelector((state) => state.serviceAreas)

  const [selectedServiceArea, setSelectedServiceArea] = useState<ServiceArea>()
  const [currentOffer, setCurrentOffer] = useState<Offer>()

  const moneySymbol = currentOffer?.rateInfo?.currency === 'USD' ? '$' : '$$'

  const actionTypes = {
    cancel: true
  }

  function actionHandler(offer: Offer, action: string) {
    if (actionTypes[action] !== undefined) {
      const data: RejectOffersRequest = {
        rejectOfferList: [offer.offerId]
      }
      dispatch(rejectOffers(data))
      /* setBlocks(blocks.filter((b) => b.id !== block.id)) */
    }
  }

  const statusReason: StatusReason = {
    status: StatusType.Accepted,
    reason: ReasonType.Ongoing
  }

  useEffect(() => {
    dispatch(offersRequest(statusReason))

    const intervalCall = setInterval(() => {
      dispatch(offersRequest(statusReason))
    }, UPDATE_OFFERS_CAPTURED_INTERVAL_MS)

    return () => {
      clearInterval(intervalCall)
    }
  }, [dispatch])

  useEffect(() => {
    if (serviceAreas !== undefined && currentOffer !== undefined) {
      for (const area of serviceAreas) {
        if (area.identification === currentOffer.serviceAreaId) {
          setSelectedServiceArea(area)
          break
        }
      }
    }
  }, [currentOffer, serviceAreas])

  return {
    offers,
    selectedServiceArea,
    setSelectedServiceArea,
    setCurrentOffer,
    actionHandler,
    moneySymbol
  }
}
