export interface Reward {
  status: RewardStatusType
  code: string
  startDate: string
  endDate: string
  token: string
}

export enum RewardStatusType {
  Expired,
  Pending,
  InProgress,
  Completed
}
