import { colors } from '@constants/colors'
import useTourProgress from '@hooks/useTourProgress'
import {
  CheckIcon,
  ColorSwatch,
  Group,
  RingProgress,
  Text
} from '@mantine/core'
import { TourStatusType } from '@models/TourStatus'
import { useIntl } from 'react-intl'
import ResetBtn from './ResetBtn'

export default function TourProgressContent({
  showResetBtn
}: {
  showResetBtn: boolean
}) {
  const { formatMessage: f } = useIntl()
  const { tourStatuses, progression, sections } = useTourProgress()

  const roundedProgression = Math.floor(progression)

  return (
    <div className="tour-progress-content flex items-center">
      <RingProgress
        size={160}
        thickness={21}
        roundCaps
        color={colors.blue[200]}
        sections={sections}
        label={
          <Text color="blue" weight={700} align="center" size="xl">
            {roundedProgression}%
          </Text>
        }
      />

      <ul>
        {tourStatuses?.map((current, index) => (
          <li key={index} className={'flex gap-3'}>
            <Group
              position="center"
              spacing="xs"
              className={
                current.status === TourStatusType.Finished && 'opacity-40'
              }
            >
              <ColorSwatch
                component="button"
                color={colors.green[50]}
                size={15}
                sx={{ color: '#fff' }}
              >
                {current.status === TourStatusType.Finished && (
                  <CheckIcon width={8} />
                )}
              </ColorSwatch>
            </Group>
            <Text
              className={
                current.status === TourStatusType.Finished && 'opacity-40'
              }
            >
              {f({ id: current.tour.name })}
            </Text>
            {showResetBtn && current.status === TourStatusType.Finished && (
              <ResetBtn tourStatus={current} />
            )}
          </li>
        ))}
      </ul>
    </div>
  )
}
